<template>
  <section class="section hero is-fullheight is-error-section">
    <div>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5">
            <div class="card has-card-header-background">
              <header class="card-header">
                <div class="card-header-title">
                  <span class="icon">
                    <i class="mdi mdi-lock default"></i>
                  </span>
                  <span>Generate Bill</span>
                </div>
              </header>
              <div class="card-content is-centered" v-if="showMeters">
                <table class="box table is-fullwidth">
                  <tbody>
                    <tr>
                      <th>Employee ID</th>
                      <td>{{ $route.params.id }}</td>
                    </tr>
                    <tr>
                      <th>Customer Name</th>
                      <td>{{ userName }}</td>
                    </tr>
                  </tbody>
                </table>
                <form @submit.stop.prevent="onSubmit">
                  <div class="box" v-for="meter in form.meters" :key="meter.name">
                      <h3 class="is-size-5 has-text-weight-bold mb-2">Meter No ({{meter.name}}) :</h3>
                      <b-field horizontal label="Reading" :message="`Previous Reading: ${meter.prev_read}. Units: ${(meter.reading - meter.prev_read)}`">
                          <b-input type="number" v-model="meter.reading" placeholder="0"></b-input>
                      </b-field>
                      <b-field horizontal label="Status">
                        <b-select placeholder="Status" expanded v-model="meter.status">
                          <option value="1">Active</option>
                          <option value="0">Faulty</option>
                        </b-select>
                      </b-field>
                  </div>

                  <footer class="card-footer">
                    <b-button type="is-primary" native-type="submit" rounded class="card-footer-item">
                      <i class="mdi mdi-printer default"></i> Generate
                    </b-button>
                  </footer>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import helper from '@/mixins/helper'
import { apiService } from '@/services'
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'UserMeters',
  mixins: [validationMixin, helper],
  data () {
    return {
      isBusy: true,
      showMeters: false,
      form: {
        meters: [{
          id: '',
          name: 'M1',
          prev_read: '0',
          reading: '0',
          status: 0
        }]
      },
      options: [
        { value: 0, text: 'Faulty' },
        { value: 1, text: 'Active' }
      ]
    }
  },
  validations: {
    userName: '',
    form: {
      user_id: '',
      meters: {
        required,
        $each: {
          name: {
            required
          }
        }
      }
    }
  },
  computed: {
    ...mapState('tariff', ['formError'])
  },
  mounted () {
    this.getMeters(this.$route.params.id) // Push the Emp Num here!
  },
  methods: {
    ...mapActions('tariff', ['generateBill']),
    ...mapActions({ showError: 'error/show' }),
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    getMeters (id) {
      apiService.postAll('/usermeters', {
        emp_num: id
      }).then((response) => {
        this.isBusy = false
        this.userName = response.data.user.name
        this.form.user_id = response.data.user.id
        this.form.meters = response.data.meters
        this.showMeters = this.form.meters.length > 0
      }).catch(error => {
        this.isBusy = false
        this.showMeters = false
        this.showError(error)
      })
    },
    onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {

      } else {
        apiService.postAll('/tariffs', this.form)
          .then((response) => {
            if (response.status === 201) {
              // router.replace('/tariffs/' + response.data.id)
              this.$router.replace({ name: 'tariff.edit', params: { id: response.data.id } })
            }
          }).catch(error => this.showError(error))
      }
    }
  }
}
</script>
